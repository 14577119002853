@import url("https://fonts.googleapis.com/css2?family=Lora&display=swap");
@import "./assets/colors.scss";
@import url("https://fonts.googleapis.com/css2?family=Schoolbell&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400&display=swap");
.App {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  align-items: center;
  color: $text;
  background: linear-gradient(to top left, $lightBrown, $darkBrown);
  // background-image: url("./assets/images/green-bg.png");
  font-family: "Montserrat", sans-serif;
  overflow-x: hidden;
  scrollbar-width: thin;
  scrollbar-color: #aa9171a6 $white;
  
  
}

/* CSS for fade-out */
.fade-out {
  animation: fadeOut 0.5s ease forwards;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.remove-hero {
  display: none;
}
/* CSS for fade-in */
.fade-in {
  animation: fadeIn ease 0.5s;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
