@import "../../assets/colors.scss";
@import "../../assets/page-layout.scss";

.hero-container {
  background-image: url("../../assets/images/bg.png");
  display: flex;
  flex-direction: column;
  gap: 4em;
  justify-content: center;
  align-items: center;
  padding-bottom: 10em;

  .hero-top {
    position: relative;
    width: 100vw;
  }
  .hero-bot {
    position: relative;
    width: 100vw;
  }
}

.__hero-bg {
  position: absolute;
  top: 15%;
  left: 10%;
  width: 50%;
}

.hero-title-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  background-color: rgba(150, 145, 91, 0.302);
  border-radius: 20px;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  width: 50%;
  top: 10%;
  right: 10%;
  padding: 2em;
  box-shadow: $box-shadow;

  .__logo {
  }

  .hero-subtitle {
    font-size: 1.25vw;
    font-weight: 300;
    color: #6f3026;
  }
}

.intro-paragraph {
  font-weight: 400;
  text-align: center;
  position: absolute;
  font-size: 1.1vw;
  width: 25%;
  right: 12.5%;
  top: 21vw;
}
.hero-buttons-container {
  display: flex;
  flex-wrap: wrap;
  gap: 2em;
  width: 100%;
  justify-content: center;
  margin: auto;
  @media screen and (min-width: 1700px) {
    margin-top: 5em;
  }
  .hero-link-row {
    display: flex;
    gap: 2em;
  }

  .link-button {
    width: 25em;
    min-width: 15em;
    height: auto;
    cursor: pointer;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
    border: 2px solid $darkBrown;
    position: relative;
    border-radius: $borderRadius;

    .link-button-img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      filter: blur(2px);
      transition: filter 0.3s ease-in-out;
      border-radius: $borderRadius;
      &:hover {
        filter: none;
      }
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(51, 51, 51, 0.2);
      transition: opacity 0.3s ease-in-out;
      border-radius: $borderRadius;
    }
    &:hover::after {
      opacity: 0;
    }
    .text-overlay {
      z-index: 1;
      border-radius: $borderRadius;
      color: $accent;
      padding: 0.25em 1em;
      background-color: rgba(68, 68, 68, 0.2);
      backdrop-filter: blur(1px);
      -webkit-backdrop-filter: blur(1px);
      font-size: 2.5vw;
      font-size: max(2vw, 26px);
      position: absolute;
      white-space: nowrap;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      cursor: pointer;
    }
    &:hover .link-button-img,
    .text-overlay:hover ~ .link-button-img {
      filter: none;
    }
  }
}
