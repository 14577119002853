@import "../../assets/colors.scss";
@import "../../assets/page-layout.scss";

.about-grid {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: 1fr 3fr;
  border-radius: $borderRadius;
  margin-top: 2em;
  height: 80vh;
  padding: 2em;
  gap: 2em;

  .grid-item {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: $borderRadius;
  }

  .about-title {
    border: 10px double $lightBrown;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
    grid-column: 1;
    grid-row: 1;
    text-align: center;
    font-family: "Railey";
    width: 100%;
    height: 100%;
    color: $accent;
    font-size: 2rem;
    background-image: url("../../assets/images/about3.png");
    background-size: cover;
    background-position: center;
    background-blend-mode: multiply;
    background-color: rgba(0, 0, 0, 0.2);
  }

  .small-img {
    justify-self: center;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
    grid-column: 1;
    grid-row: 2;
    width: 100%;
    height: 100%;
    background-image: url("../../assets/images/about1.png");
    background-size: cover;
    overflow: hidden;
    background-position: center;
    padding: 0;
    margin: 0;
    border-radius: $borderRadius;
    border: 10px double $blue;
  }

  .big-img {
    grid-column: 2;
    grid-row: 1 / span 2;
    border-radius: $borderRadius;
    border: 10px double $darkBrown;
    overflow: hidden;
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  @media (max-width: 750px) {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto;
    height: auto;
    gap: 1em;

    .about-title {
      grid-column: 1;
      grid-row: 1;
      padding: 1em;
      height: 15vh;
    }

    .small-img {
      visibility: hidden;
      grid-column: 1;
      grid-row: 2;
      height: 0;
      width: 0%;
    }

    .big-img {
      grid-column: 1;
      grid-row: 3;
      height: 40vh;
      width: 100%;
    }
  }
}

.about-content-container {
  margin-top: 2em;
  margin-inline: 10%;
}
.meet-the-gang {
  margin-top: 2em;
  margin-inline: 10%;
  h2 {
    padding-bottom: 1em;
    text-align: center;
  }
  .gang-container {
    display: flex;
    justify-content: center;
    gap: 2em;
    .gang-member {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      p {
        text-align: center;
        width: 90%;
      }
    }
    .member-img {
      width: 30vw;
      height: 30vw;
      background-size: cover;
      background-position: center;
    }
  }
}
.come-visit {
  h2 {
    margin-top: 2em;
  }
}
.come-visit-section {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  gap: 5%;
  h2 {
    margin-bottom: 1em;
    @media (max-width: 950px) {
      margin: 0;
    }
  }

  .come-visit-img {
    border-radius: $borderRadius;
    box-shadow: $box-shadow;
    margin-top: 3em;
    width: 100%;
    align-self: center;
    min-height: 20vw;
    background-size: cover;
    background-position: center;
    background-image: url("../../assets/images/come-visit.jpg");
  }
}
.google-maps-section {
  margin-top: 2em;
  margin-bottom: 2em;
  display: flex;
  gap: 2em;
  justify-content: center;
  align-items: center;
  margin-inline: 10%;
  border-radius: $borderRadius;
  iframe {
    border-radius: $borderRadius;
  }
  .map-container {
    flex: 2;
    border-radius: $borderRadius;
  }
  @media (max-width: 700px) {
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 750px) {
  .come-visit-section {
    flex-direction: column;
    align-items: center;
    .come-visit-img {
      min-height: 30vw;
      width: 80vw;
      margin: 0;
    }
  }
}
