@import "../assets/colors.scss";
@import "../assets/page-layout.scss";

.cards-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 20px;
  gap: 20px;

  .product-card {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 200px;
    height: 250px;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
    padding: 15px;
    border-radius: $borderRadius;
    border: 2px solid $blue;
    background-color: #f7f7f7;
    @media screen and (max-width: 850px) {
      width: 80%;
      height: 250px;
    }
    .overlay {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) scale(0.9);
      background-color: #33333381;
      color: white;
      padding: 20px;
      border-radius: 10px;
      font-size: 1.5em;
      z-index: 1000;
      text-align: center;
      opacity: 0;
      animation: fadeInOutPop 2s cubic-bezier(0.34, 1.56, 0.64, 1);
    }
    &:hover {
      img {
        // transform: scale(1.1) rotate(-15deg);
      }
    }
    .img-container {
      align-self: center;
      height: 110px;
      width: 110px;
      display: flex;
      justify-content: center;
      align-items: center;
      -webkit-filter: drop-shadow(5px 5px 5px #222);
      filter: drop-shadow(5px 5px 5px #222);
      @media screen and (max-width: 850px) {
        width: 70px;
        height: 70px;
      }
      img {
        border-radius: $borderRadius;
        // transition: transform 0.3s;
        // transform: scale(1) rotate(0deg);
      }
    }

    h3 {
      margin-top: 15px;
      font-size: 16px;
    }
    .price {
      font-size: 18px;
      font-weight: 400;
    }
    .card-bot {
      display: flex;
      justify-self: flex-end;
      justify-content: space-between;
      align-items: center;
      margin-top: 15px; //change this padding
      @media screen and (max-width: 850px) {
        flex-direction: column;
      }
    }
    .add-to-cart-btn {
      padding: 2px 4px;
      border: none;
      font-size: 14px;
      border-radius: $borderRadius;
      background-color: $lightBrown;
      color: white;
      cursor: pointer;
      transition: background-color 0.3s;
      @media screen and (max-width: 850px) {
        order: 2;
      }
      &:disabled {
        background-color: #7a7a7a;
        opacity: 0.8;
        color: #333;
        cursor: default;
        &:hover {
          background-color: #7a7a7a;
        }
      }
      &:hover {
        background-color: $orange;
      }
    }
    //MOBILE
    @media screen and (max-width: 850px) {
      button {
        font-size: 12px;
        margin-top: 0.5em;
      }
      .available,
      .unavailable {
        align-self: center;
        font-size: 14px;
      }
      h3 {
        align-self: center;
        font-size: 16px;
      }
    }
  }
  .available {
    color: $lightGreen;
    font-weight: 400;
  }
  .unavailable {
    font-weight: 400;
    color: $red;
  }
}

// ANIMATIONS
@keyframes fadeInOutPop {
  0%,
  100% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(0.9);
  }
  30% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1.2); /* Scale up for the 'pop' */
  }
}
