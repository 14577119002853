@import "../../assets/colors.scss";
@import "../../assets/page-layout.scss";


  .checkout-container {
    background-color: $accent;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    .checkout-title-container {
      margin: 1em;
      margin-left: 5%;
      gap: 1em;
      display: flex;
      flex-direction: column;
      align-self: flex-start;
      .back-to-cart-btn {
        display: flex;
        width: fit-content;
        cursor: pointer;
        text-decoration: none;
        background-color: $blue;
        color: $accent;
        align-items: center;
        padding: 0.5em 0.75em;
        border-radius: $borderRadius;
      }
    }
    .checkout-form-container {
      margin-top:2em;
      display: flex;
      justify-content: center;
      width: 100%;
      height: 100%;
    }
    .loading{
      height:40vw;
    }
}
