@import "../../assets/colors.scss";
@import "../../assets/page-layout.scss";

.admin-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: $borderRadius;
  margin: 2em;
  padding: 2em;
  @media (max-width: 450px) {
    margin: 0;
  }

  .admin-title {
    padding-bottom: 10px;
    padding: 1em;
    border-radius: $borderRadius;
    width: 80%;
    color: $accent;
    background-color: $blue;
    text-align: center;
    @media (max-width: 450px) {
      width: 100%;
    }
  }
  .logged-in-content-container {
    width: 100%;
    .confirmEtransfers {
      border-radius: $borderRadius;
      width: 100%;

      margin: 0.25em;
      max-height: 600px;
      resize: vertical;
      overflow-y: scroll;
      display: flex;
      flex-direction: column;
      gap: 1em;
      align-items: center;
      @media (max-width: 1100px) {
        align-items: flex-start;
        overflow-x: scroll;
      }
    }
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .submit-button {
      margin: 1em;
      background-color: #f4f4f4;
      border: 1px solid $blue;
      border-radius: $borderRadius;
      width: 50%;
    }
    .coupon-container {
      padding: 1em;
      display: flex;
      gap: 2em;
      border-radius: $borderRadius;
      width: 100%;
      border: 1px solid $blue;
    }
  }
  .credential-container {
    background-color: $lightBrown;
    border-radius: $borderRadius;
    gap: 1em;
    padding: 1em;
    display: flex;
    flex-direction: column;
    width: 50%;
    justify-content: center;
    align-items: center;
    margin-top: 2em;
    @media (max-width: 700px) {
      width: 100%;
      min-width: 220px;
    }
  }

  .confirm-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    .confirm-modal-content {
      width:40%;
      background-color: $accent;
      padding: 1em;
      border-radius: $borderRadius;
      box-shadow: $box-shadow;
    }
    .confirm-modal-header {
      margin-bottom: 1em;
    }
    .confirm-modal-footer {
      text-align: right;
      margin-top: 1em;
      button {
        padding: 0.25em;
        margin-left: 1em;
      }
    }
  }
  // .admin-console {
  //   background-color: $lightBrown;
  //   padding:2em;
  //   width: 50%;
  //   border-radius: $borderRadius;
  //   .current-file-info{
  //     display: flex;
  //     gap:2em;
  //     border-radius: $borderRadius;
  //     align-items: center;
  //     border:2px solid #ffffff00;
  //     transition:border 0.2s ease;
  //     &:hover{
  //       border:2px solid #3333333a;
  //     }
  //     img{
  //       height:5em;
  //       width:5em;
  //     }
  //   }
  // }
}
