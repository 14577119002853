@import "../assets/colors.scss";
@import "../assets/page-layout.scss";
.carousel {
  position: relative;
  width: 100%;
  height: 100%;
  margin: auto;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.caret-container {
  display: flex;
  justify-content: center;
  padding: 1vw;
}

.caret {
  width: 1vw;
  height: 1vw;
  border-radius: 50%;
  background-color: #bbb;
  margin: 0 0.5vw;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.caret.active {
  background-color: #333; /* Active caret color */
}

.slides-container {
  position: relative;
  width: 100%;
  height: 50vw;
  max-height:600px;
}
.slides-container.big-carousel{
  max-height:50vw;
}
.slide {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
  overflow: hidden;
  padding:1em;
  border-radius: $borderRadius;
}
.slide.cover {
  background-size: cover;
}
.slide.contain {
  background-size: contain;
  border:2px solid $accent;
}

.slide.visible {
  opacity: 1;
  // transition: opacity 0.5s ease-in-out;
}

.slide.hidden {
  visibility: hidden;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .caret {
    width: 2vw;
    height: 2vw;
  }
}
