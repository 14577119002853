@font-face {
  font-family: "Railey";
  src: url("./fonts/Railey-PersonalUse.ttf") format("truetype");
}
.Loader {
  font-family: "Railey", sans-serif;
}
.content-container {
  background-color: $white;
  z-index: 1;
  margin-top: 9em;
  margin-bottom: 2em;
  box-shadow: 0 0 15px 0 rgba(63, 63, 63, 0.781);
  @media screen and (max-width: 950px) {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    margin-bottom: 0;
  }
  width: 90%;
  min-height: 100vh;
  border-radius: 20px;
}

@media (max-width: 945px) {
  .content-container {
    width: 100%;
  }
}
.button-animation {
  transition: filter 0.3s, transform 0.3s;
  -webkit-filter: drop-shadow(1px 1px 5px #808080);
  filter: drop-shadow(1px 1px 3px #808080);

  &:hover {
    -webkit-filter: drop-shadow(2px 2px 5px #808080);
    filter: drop-shadow(2px 2px 5px#808080);
    transform: translateY(2px);
  }

  &:active {
    -webkit-filter: drop-shadow(2px 2px 5px #808080);
    filter: drop-shadow(2px 2px 5px #808080);
    transform: translateY(4px);
  }
}
