$borderRadius: 5px;

$gray: #333;
$white: #f7f7f7;
$text: #3b2715;
$linkText: #303816;
$accent: #e5e5d3;
$lightGreen: #717728;
$darkGreen: #313c15;
$lightBrown: #aa9171;
$darkBrown: #372613;
$orange: #d28b28;
$blue: #33373f;
$red: #b7272a;
$lightGray: rgb(190, 190, 190);

$box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.3);
