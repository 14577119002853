@import "../assets/colors.scss";
.overlay {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.9);
  background-color: #33333381;
  color: white;
  padding: 20px;
  border-radius: 10px;
  font-size: 1.5em;
  z-index: 100000;
  text-align: center;
  opacity: 0;
  animation: fadeInOutPop 2s cubic-bezier(0.34, 1.56, 0.64, 1);
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  background-color: $accent;
  padding: 2em;
  border-radius: 5px;
  max-width: 800px;
  width: 100%;
  gap: 2em;
  display: flex;
  justify-content: center;
  position: relative;
  max-height: 70%;
  z-index: 201;

  .modal-left {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1em;
    .price {
      color: $orange;
      font-size: 1.4rem;
      font-weight: 400;
    }
    .available {
      font-weight: 400;
      color: $lightGreen;
    }
    .unavailable {
      font-weight: 400;
      color: $red;
    }
    .product-description-container {
      max-height: 15em;
      overflow-y: hidden;
    }
    .product-description-container.scrollable {
      overflow-y: auto; /* Show scrollbar when content overflows */
    }

    .quantity-input-container {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 1em;
      padding: 1em;

      input {
        font-weight: 400;
        text-align: center;
        border: 2px solid $lightBrown;
        width: 4em;
        border-radius: $borderRadius;
      }
      button {
        padding: 5px 10px;
        border: none;
        border-radius: $borderRadius;
        background-color: $orange;
        color: white;
        cursor: pointer;
        transition: background-color 0.3s ease-in-out;

        &:hover {
          background-color: $lightBrown;
        }
        &:disabled {
          background-color: #7a7a7a;
          opacity: 0.8;
          color: #333;
          cursor: default;
          &:hover {
            background-color: #7a7a7a;
          }
        }
      }
    }
  }
  .modal-right {
    flex-direction: column;
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    width: 40%;
    gap: 2em;
    img {
      -webkit-filter: drop-shadow(5px 5px 5px #222);
      filter: drop-shadow(5px 5px 5px #222);
      width: 200px;
      height: auto;
    }
    .product-detail-container {
      display: flex;
      flex-direction: column;
      gap: 1em;
      .product-detail {
        display: flex;
        gap: 0.5em;

        .__tooltip {
          flex-shrink: 0;
          width: 1.5rem;
          height: 1.5rem;
          transition: filter 0.3s, transform 0.3s;

          &:hover {
            -webkit-filter: drop-shadow(1px 1px 5px #d3da0f);
            filter: drop-shadow(1px 1px 3px #d3da0f);
          }
        }
        span.bold {
          white-space: nowrap;
        }
      }
    }
  }
}
.mobile-product-img {
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    -webkit-filter: drop-shadow(5px 5px 5px #222);
    filter: drop-shadow(5px 5px 5px #222);
    max-width: 100px;
    height: auto;
  }
}
@media screen and (min-width: 500px) {
  .mobile-product-img {
    visibility: hidden;
    position: absolute;
  }
}
@media screen and (max-width: 500px) {
  .modal {
    flex-direction: column;
    align-items: center;
    .modal-left {
      width: 80%;
    }
  }
  .modal-right {
    visibility: hidden;
    position: absolute;
  }
}

.close-button {
  position: absolute;
  top: 10px;
  right: 20px;
  border: none;
  background: none;
  cursor: pointer;
  font-size: 40px;
}
@keyframes fadeInOutPop {
  0%,
  100% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(0.9);
  }
  30% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1.2); /* Scale up for the 'pop' */
  }
}
