@import "../../assets/colors.scss";
@import "../../assets/page-layout.scss";

.services-hero {
  display: flex;
  width: calc(100% - 0.5em);
  margin: 0.25em;
  align-items: center;
  justify-content: space-around;
  .services-hero-left {
    padding: 0.5em;
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .services-hero-right {
    padding: 1em;
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 1em;
    max-width: 550px;
    h1 {
      margin-bottom: 1em;
    }
    @media screen and (max-width: 1000px) {
      width: 90%;
    }
  }
}
.possible-rentals {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  div {
    max-width: 500px;
    border: 2px solid $accent;
    margin: 1em;
    padding: 1em;
    width: 50%;
    ul {
      list-style-position: inside;
      li {
        list-style-type: circle;
        font-weight: bold;
        padding: 0.25em;
        cursor: pointer;
        transition: color 0.2s ease-in-out;
        &:hover {
          color: $lightBrown;
        }
      }
    }
  }
  @media screen and (max-width: 1000px) {
    flex-direction: column;
    div {
      width: 90%;
    }
  }
}
a {
  text-decoration: none;
  font-weight: bold;
  color: $orange;
  transition: color 0.3s ease-in-out;
  &:hover {
    color: $blue;
  }
}
.contact-us {
  font-weight: bold;
  color: $orange;
}
.rental-container {
  display: flex;
  height: 80vh;
  max-height: 80vh;
  padding: 2em 0em;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  gap: 5%;
  .left {
    display: flex;
    flex-direction: column;
    width: 40%;
    gap: 1em;
    margin-left: 5%;
    @media (max-width: 1000px) {
      width: 90%;
      margin-left: 0;
    }
  }
  .right {
    width: 45%;
    margin-right: 5%;
  }
  @media (max-width: 1000px) {
    height: 100%;
    max-height: 100%;
  }
}
.inverse-rental-container {
  display: flex;
  height: 80vh;
  max-height: 80vh;
  padding: 2em 0em;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  gap: 5%;
  .left {
    width: 45%;
    margin-left: 5%;
  }
  .right {
    display: flex;
    flex-direction: column;
    width: 40%;
    gap: 1em;
    margin-right: 5%;
    @media (max-width: 1000px) {
      width: 90%;
      margin-right: 0;
    }
  }
  @media (max-width: 1000px) {
    height: 100%;
    max-height: 100%;
  }
}
