@import "../../assets/colors.scss";
@import "../../assets/page-layout.scss";

.cart-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1em;
  height: 100%;
  align-items: center;
  width: 100%;
  background-color: $accent;
  padding-bottom: 2em;
  padding-top: 2em;
  border-radius: $borderRadius;
  .cart-title-container {
    margin: 1em;
    margin-bottom: 0;
    margin-left: 5%;
    align-self: flex-start;

    .back-to-shop-btn {
      margin-top: 1em;
      display: flex;
      width: fit-content;
      cursor: pointer;
      text-decoration: none;
      background-color: $blue;
      color: $accent;
      align-items: center;
      padding: 0.5em 0.75em;
      border-radius: $borderRadius;
    }
  }
  .cart-content-container {
    display: flex;
    padding: 1em;
    height: 100%;
    width: 100%;
    gap: 1em;
    justify-content: center;

    @media screen and (max-width: 945px) {
      flex-direction: column;
      align-items: center;
    }
    .default-msg {
      width: 80%;
    }
    .items-in-cart-container {
      overflow-y: scroll;
      padding: 1em;
      padding-top: 0;
      width: 55%;
      max-height: 60vh;
      display: flex;
      flex-direction: column;
      gap: 1em;
      @media screen and (max-width: 945px) {
        width: 100%;
        min-height: 60%;
        order: 1;
      }
      .cart-card {
        display: flex;
        border-radius: $borderRadius;
        background-color: #f9f9f9;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        padding: 1em;
        .cart-image-container {
          width: auto;
          overflow: hidden;
          border-radius: $borderRadius;
          display: flex;
          justify-content: center;
          align-items: center;
          flex: 2;
          img {
            -webkit-filter: drop-shadow(2px 2px 2px #222);
            filter: drop-shadow(2px 2px 2px #222);
            width: 80px;
            height: auto;
            overflow: visible;
            object-fit: cover;
          }
        }
        .cart-info-container {
          flex: 8;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .cart-card-top {
            align-items: center;
            justify-content: space-between;
            border-radius: $borderRadius;
            padding-top: 1em;
            padding-left: 1em;
            padding-right: 1em;
            display: flex;
            flex-direction: row;
            .cart-item-title {
              flex: 4;
              font-size: 18px;
              font-weight: 400;
            }
            .size {
              font-size: 14px;
            }

            .num-items-container {
              display: flex;
              flex: 2;
              gap: 1em;
              @media screen and (max-width: 945px) {
                flex-direction: column;
              }
              input[type="number"] {
                width: 4em;
                font-weight: 400;
                text-align: center;
                border-radius: $borderRadius;
              }
              input[type="number"]::-webkit-inner-spin-button {
                opacity: 1;
              }
            }
            p {
              font-weight: 400;
            }
          }

          .cart-card-bot {
            width: 100%;
            display: flex;
            padding: 1em;
            justify-content: flex-end;
            align-items: flex-end;
            .remove-btn {
              display: flex;
              background-color: $blue;
              color: $accent;
              border: none;
              align-items: center;
              padding: 0.25em 0.5em;
              border-radius: $borderRadius;
              @media screen and (max-width: 945px) {
                padding: 0.25em;
              }
            }
          }
        }
      }
    }
    .order-summary-container {
      border-radius: $borderRadius;
      background-color: rgb(190, 190, 190);
      font-weight: 400;
      display: flex;
      flex-direction: column;
      gap: 1vw;
      padding: 2em;
      width: 30%;
      height: 60vh;

      @media screen and (max-width: 945px) {
        width: calc(100% - 1em);
        order: 1;
      }
      .order-summary-title-container {
        border-bottom: 2px solid $darkBrown;
        padding-bottom: 1em;
      }
      .order-financial-info {
        display: flex;
        flex-direction: column;
        & > div {
          display: flex;
          font-size: 0.8rem;
          justify-content: space-between;
        }
        .total {
          font-size: 1rem;
          display: flex;
        }
      }
      .conditional-coupon {
        color: $lightGreen;
      }
      .coupon-container {
        display: flex;
        flex-direction: column;
        #coupon-input {
          max-width: 150px;
          border-radius: $borderRadius;
          border: none;
          margin: 0.5em 0em;
          padding: 0.25em;
          &:focus {
            border: none;
            outline: none;
          }
        }
        .apply-coupon-btn {
          width: 30%;
          border-radius: $borderRadius;
          border: none;
          padding: 0em 0.25em;
        }
      }
      .invalid-coupon-message {
        color: $red;
        padding-bottom: 0.25em;
      }
      .spacer {
        flex-grow: 1;
      }
      .proceed-to-checkout-btn {
        margin-top: 1em;
        text-decoration: none;
        justify-content: center;
        display: flex;
        color: $accent;
        cursor: pointer;
        background-color: $blue;
        text-align: center;
        padding: 0.5em 0.75em;
        border-radius: $borderRadius;
        margin-top: auto;
      }
      .link-disabled {
        pointer-events: none;
        color: grey;
        text-decoration: none;
        cursor: default;
      }
    }
  }
}
