@import "../../assets/colors.scss";
@import "../../assets/page-layout.scss";
@import "../../assets/colors.scss";
@import "../../assets/page-layout.scss";

.hero-mobile-container {
  background-image: url("../../assets/images/bg.png");
  padding: 2em;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.hero-mobile-title-container {
  display: flex;
  margin-top: 2em;
  margin-bottom: -1em;
  padding: 1em;
  flex-direction: column;
  align-items: center;
  border-radius: 20px;
  justify-content: center;
  background-color: rgba(150, 145, 91, 0.102);
  border-radius: 20px;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);

  .__mobile-logo {
    width: 80%;
  }
  .hero-mobile-subtitle {
    font-size: 2.5vw;
    text-align: center;
    font-weight: 400;
    color: #6f3026;
  }
}

.hero-mobile-body-container {
  display: flex;
  flex-direction: column;
  gap: 2em;
  align-items: center;
  .__hero-mobile-bg {
    width: 80%;
  }
  .mobile-intro-paragraph {
    font-weight: 300;
    text-align: center;
    font-size: 3vw;
    width: 80%;
  }
}

//screens smaller than 500px
@media screen and (max-width: 500px) {
  .hero-mobile-title-container {
    .__mobile-logo {
      width: 95%;
    }
    .hero-mobile-subtitle {
      font-size: 4vw;
    }
  }
  .hero-mobile-body-container {
    .__hero-mobile-bg {
      width: 100%;
    }
    .mobile-intro-paragraph {
      font-size: 4vw;
      width: 90%;
    }
  }
}

.hero-mobile-link-row {
  margin-top: 2em;
  display: flex;
  flex-direction: column;
  gap: 2em;
}
