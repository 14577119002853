@import "../assets/colors.scss";
@import "../assets/page-layout.scss";

@keyframes float {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-15px);
  }
}

.animated-image {
  width: 4rem;
  height: auto;
  animation: float 3s ease-in-out infinite;
}
.loading-text {
  font-family: "Railey";
  font-weight: 300;
  font-size: 3.5rem;
}

.loader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  background-color: $accent;
  z-index: 9999;
}
