@import "../assets/colors.scss";

.modal.mobile-modal-container {
  margin: 1em 0em;
  max-height: 100%;
  max-width:100vw;
  overflow-y: scroll;
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 1em;
  padding-top: 1.5em;
  gap: 0;
}
.mobile-modal-left {
  gap: 1em;
  width: 90%;
  height: 100%;
  h2 {
    text-align: center;
  }
  .mobile-modal-header {
    display: flex;
    width: 100%;
    justify-content: space-around;
    padding: 1em;
    gap: 0.5em;
    align-items: center;
    img {
      max-width: 50%;
    }

    .col {
      display: flex;
      flex-direction: column;
    }
    .price {
      color: $orange;
      font-size: 1.4rem;
      font-weight: 400;
    }
    .available {
      font-weight: 400;
      color: $lightGreen;
    }
    .unavailable {
      font-weight: 400;
      color: $red;
    }
  }
  .product-description-container {
    max-height: 12em;
    overflow-y: scroll;
  }
  .product-detail-container {
    width: 100%;
  }
  .product-detail {
    width: 100%;
    margin: 1em 0em;
    display: flex;
    flex-direction: row;
    align-items: center;
    p {
      width: fit-content;
    }
  }
  .quantity-input-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1em;
    padding: 1em;

    input {
      font-weight: 400;
      text-align: center;
      border: 2px solid $lightBrown;
      width: 8em;
      font-size: 1.2rem;
      border-radius: $borderRadius;
    }
    button {
      padding: 5px 30px;
      border: none;
      border-radius: $borderRadius;
      background-color: $blue;
      color: white;
      cursor: pointer;
      transition: background-color 0.3s ease-in-out;

      &:disabled {
        background-color: #7a7a7a;
        opacity: 0.8;
        color: #333;
        cursor: default;
        &:hover {
          background-color: #7a7a7a;
        }
      }
    }
  }
  .add-to-cart {
    width: 100%;
    button {
      padding: 5px 10px;
      border: none;
      border-radius: $borderRadius;
      background-color: $orange;
      color: white;
      cursor: pointer;
      transition: background-color 0.3s ease-in-out;

      &:disabled {
        background-color: #7a7a7a;
        opacity: 0.8;
        color: #333;
        cursor: default;
      }
    }
  }
  .__tooltip {
    height: 3rem;
    width: 3rem;
  }
}
