@import "../assets/colors.scss";

.header {
  background-color: $accent;
  position: absolute;
  display: flex;
  flex-direction: row;
  gap: 6em;
  padding: 2em;
  padding-right: 3em;
  top: 0.5em;
  left: 0.5em;
  height: calc(9em + (30 - 9) * ((100vw - 945px) / (945 - 480)));
  min-height: 9em;
  border-radius: 20px;
  box-shadow: inset 0 0 5px 0 rgba(80, 57, 6, 0.781);
  @media (max-width: 590px) {
    gap: 2em;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    z-index: 20;
    border-bottom: 2px solid $darkBrown;
    padding: 1em;
    padding-right: 1.5em;
    align-items: center;
    top: 0;
    left: 0;

    a {
      display: flex;
      align-items: center;
    }
  }

  .logo-container {
    height: 6em;
    display: flex;
    align-items: center;
    justify-content: center;

    .__maple-grove-permaculture {
      height: 8vw;
      max-height: 5em;
      min-height: 2em;
      object-fit: cover;
      //mobile
      @media (max-width: 590px) {
        object-fit: contain;
        height: 15vw;
      }
    }
  }
  .links-container {
    display: flex;
    height: 5em;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 2.5em;

    .header-link {
      color: $text;
      text-decoration: none;
      cursor: pointer;
      font-size: 0.75rem;
      text-align: center;
      text-decoration: none;
      transition: transform 0.2s ease-in-out, color 0.2s ease-in-out;
      &:hover {
        color: $lightBrown;
      }
    }
    .active {
      transform: scale(1.1);
      color: $lightBrown;
      border-bottom: 2px solid $lightBrown;
    }
    // sidebar
    @media (max-width: 1100px) {
      background-image: url("../../src/assets/images/bg.png");
      opacity: 0.99;
      position: fixed;
      border-left: 15px double $darkBrown;
      top: 0;
      right: 0;
      height: 100%;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      padding: 4em;
      padding-top: 10em;
      transform: translateX(100%);
      transition: 0.5s ease;
      z-index: 200;
      .close-icon {
        position: absolute;
        top: 0.5em;
        right: 0.5em;
        font-size: 35px;
        cursor: pointer;
      }
    }
    &.active {
      z-index: 200;
      transform: translateX(0);
    }
  }

  .hamburger-icon {
    cursor: pointer;
    align-self: center;
    font-size: 35px;
  }
}

//when screen is small
@media (max-width: 1000px) {
  .header {
    width: 100%;
    justify-content: space-between;
    height: 9em;
    transition: height 0.3s ease;
  }
}

//when screen is big
@media (min-width: 1100px) {
  .header {
    height: 30vw;
  }
  .hamburger-icon {
    display: none;
  }
  .close-icon {
    display: none;
  }
}
