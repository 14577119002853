
@import "../assets/colors.scss";
@import "../assets/page-layout.scss";
.footer {
  background-color: #f0f0f0; // Example background color
  padding: 20px 10vw;
  width: 100%;
  position:relative;
  display: flex;
  justify-content: space-between;  
  align-items: center;
  @media (max-width: 750px) {
    padding:20px 5px;
  }
  @media (max-width: 570px) {
    flex-direction: column;
    gap:1em;
  }
  .right{
    display: flex;;
    flex-direction: column;
    align-items: flex-end;
    @media (max-width: 570px) {
      padding-top:1em;
      border-top:1px solid #333;
    }
  }

  .contact-pair{
    display: flex;
    align-items: center;
    gap:1em;
    text-decoration: none;
    color:$darkBrown;
    img{
      width:5vw;
      max-width:50px;
      height:5vw;
      max-height:50px;
    }
  }
  .link:hover{
    color:$lightBrown;
  }
  
}
