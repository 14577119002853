@import "../../assets/colors.scss";
@import "../../assets/page-layout.scss";

.thank-you-container {
  z-index: 1000;
  margin-inline: 20%;
  padding: 2em;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 1em;
  @media screen and (max-width: 550px) {
    margin-inline: 5%;
  }
  .back-to-shop-btn {
    display: flex;
    width: fit-content;
    cursor: pointer;
    text-decoration: none;
    background-color: $blue;
    color: $accent;
    align-items: center;
    padding: 0.5em 0.75em;
    border-radius: $borderRadius;
  }
  .__thankyou-img {
    margin-top: -10%;
  }
}
.bold {
  font-weight: bold;
}
