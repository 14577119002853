@import "../../assets/colors.scss";
@import "../../assets/page-layout.scss";

.form-container {
  display: flex;
  flex-direction: column;
  gap: 1em;
  width: 100%;
  max-width: 50vw;
  height: 100%;
  align-items: center;
  @media screen and (max-width: 850px) {
    max-width: 100%;
  }

  .contact-details {
    border-radius: $borderRadius;
    background-color: $blue;
    color: $lightGray;
    display: flex;
    gap: 1em;
    padding: 2em;
    flex-direction: column;
    width: 100%;
    h2 {
      padding-left: 0.5em;
    }
    .form-group {
      display: flex;
      justify-content: center;
      flex-direction: column;
      width: 100%;
      label {
        padding-left: 1em;
      }
      input {
        border-radius: $borderRadius;
        border: 1px solid $text;
        box-shadow: $box-shadow;
        padding: 0.5em;
        margin: 0.5em;
      }
    }
    @media screen and (max-width: 750px) {
      gap: 1em;
      flex-direction: column;
      width: 100%;
      align-items: center;
    }
  }

  .pickup-locations {
    border-radius: $borderRadius;
    background-color: $blue;
    color: $lightGray;
    width: 100%;
    padding: 1em 2em;
    gap: 1em;
    .location-item {
      display: flex;
      gap: 1em;
      padding: 0.25em;
      border-radius: $borderRadius;
      border: 1px solid gray;
      $box-shadow: 3px 3px 5px 0px rgba(233, 233, 233, 0.3);

      margin: 0.5em 0em;
      .location {
        font-size: 1.2rem;
        font-weight: bold;
      }
    }
  }
  .order-summary-container {
    display: flex;
    flex-direction: column;
    padding: 2em;
    gap: 1em;
    width: 100%;
    justify-content: center;
    align-items: space-around;
    border-radius: $borderRadius;
    background-color: $lightGray;
    color: $gray;
    .order-summary-title-container {
      border-bottom: 2px solid $darkBrown;
    }
    .order-financial-info {
      padding-top: 1em;
      gap: 1em;
      display: flex;
      flex-direction: column;
      & > div {
        display: flex;
        justify-content: space-between;
      }
      .total {
        border-top: 2px solid $darkBrown;
        display: flex;
        justify-content: space-between;
      }
    }
  }
  .payment-section {
    display: flex;
    flex-direction: column;
    gap: 2em;
    padding: 2em;
    width: 100%;
    color: $gray;
    justify-content: center;
    align-items: center;
    border-radius: $borderRadius;
    background-color: $lightGray;
    margin-bottom: 1em;

    .etransfer-payment-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      justify-content: center;
      width: 80%;
    }
    .pay-with-btn {
      border-radius: $borderRadius;
      border: none;
      padding: 0.5em;
      box-shadow: 1px 1px 8px #8a8a8a;
      &:hover {
        background-color: #ffffff;
      }
    }
    .submit-button {
      margin-bottom: 1em;
      background-color: $blue;
      border-radius: $borderRadius;
      border: none;
      color: $accent;
      padding: 0.25em 1em;
      cursor: pointer;
      &:disabled {
        cursor: auto;
        background-color: $gray;
        color: #8a8a8a;
        transform: translateY(2px);

        &:hover {
          -webkit-filter: drop-shadow(0px 0px 0px #808080);
          filter: drop-shadow(0px 0px 0px#808080);
          transform: translateY(2px);
        }
      }
    }
    .bold {
      font-weight: bold;
    }
  }
}
