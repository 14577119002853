@import "../../assets/colors.scss";
@import "../../assets/page-layout.scss";

.shopping-container {
  display:flex;
  height:85vh;
  @media screen and (max-width: 950px) {
    flex-direction: column;
    height: fit-content;
  }
  .store-sidebar {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 1em;
    height: 100%;
    border-radius: $borderRadius 0 0 $borderRadius;
    background-color: $blue;
    @media screen and (max-width: 950px) {
      padding: 0;
      margin-bottom: 1em;
      width: 80%;
      align-self: center;
      border-radius: $borderRadius;
    }
    .dropdown-btn {
      width: 100%;
      border: none;
      border-radius: $borderRadius;
      background-color: $blue;
      color: $accent;
      padding: 0.25em;
      margin: 0.25em 0em;
      @media screen and (min-width: 950px) {
        visibility: hidden;
        position: absolute;
      }
    }
    .sidebar-top {
      display: flex;
      flex-direction: column;
      gap: 2em;
      width: 90%;

      .store-categories {
        font-size: min(1.5vw, 24px);
        font-weight: 400;
        //MOBILE
        @media screen and (max-width: 950px) {
          font-size: 18px;
        }
        .category-list {
          display: flex;
          padding-left: 0.5em;
          flex-direction: column;
          transition:max-height 0.3s ease-in-out;
          @media screen and (max-width: 950px) {
            max-height:0;
            overflow:hidden;
            font-size: 18px;
          }
          &.open{
            max-height: 500px;
          }
          li {
            color: $lightBrown;
            border-radius: $borderRadius;
            padding: 0.25em;
            margin: 0.25em 0em;
            cursor: pointer;
            transition: background-color 0.2s ease;
            &:hover {
              color: $darkBrown;
              background-color: $accent;
            }
          }
          li.active {
            color: $accent;
            background-color: #d28b289f;
          }
        }
      }
    }
    .sidebar-bottom {
      width: 100%;
      display: flex;
      justify-content: center;

      .checkout-button {
        text-decoration: none;
        color: $text;
        display: flex;
        cursor: pointer;
        width: 80%;
        background-color: $accent;
        gap: 1em;
        align-items: center;
        padding: 0.75em 1.25em;
        border-radius: $borderRadius;
        justify-content: space-around;
        .updating-cart-items {
          text-align: center;
          border: 2px solid $lightBrown;
          padding: 0.5em;
          border-radius: $borderRadius;
          @media screen and (min-width: 950px) and (max-width: 1200px) {
            display: none;
          }
        }
        .__checkout {
          height: 40px;
        }
      }

      //MOBILE
      @media screen and (max-width: 950px) {
        flex-direction: column;
        .checkout-button {
          position: fixed;
          bottom: 0;
          z-index: 200;
          right: 0;
          max-width:300px;
          margin: 1em;
          opacity: 0.9;
        }
      }
    }
  }
  .store-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 0 $borderRadius $borderRadius 0;
    background-color: #ccc8c0;
    flex: 3;
    overflow-y: scroll;
    .content-header-container {
      position:sticky;
      z-index: 199;
      top:0;
      background-color: $blue;
      width: 100%;
      color: $accent;
      justify-content: space-between;
      display: flex;
      gap: 2em;
      padding: 1em;
      align-items: center;
      font-weight: 400;
      .search-container {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        @media screen and (max-width: 950px) {
          width: 80vw;
        }
        .search-input {
          background-color: $accent;
          width: 100%;
          border-radius: $borderRadius;
          border: 2px solid $text;
          outline: none;
          padding: 0.5em;
          padding-left: 50px;
        }
        .search-icon {
          background-color: transparent;
          position: absolute;
          cursor: text;
          left: 10px;
          top: 0.5em;
          border: none;
          .__search {
            width: 30px;
            opacity: 0.8;
            height: 30px;
          }
        }
      }
      //MOBILE
      @media screen and (max-width: 950px) {
        flex-direction: column;
        .sort-by {
          display: none;
        }
      }
      .available-container {
        display: flex;
        gap: 0.5em;
      }
      div {
        align-items: center;
        display: flex;
        gap: 1em;
      }
      .sort-container {
        display: flex;
        gap: 0.5em;
      }
      .sort-select {
        border-radius: $borderRadius;
        padding: 0.5em;
        background-color: $accent;
        &:active,
        &:focus {
          outline: 1px solid $darkBrown;
        }
        option:hover {
          background-color: #33373f;
          color: white;
        }
        .availability-checkbox:checked {
          accent-color: $orange;
        }
      }
    }
    
    .cards-container {
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: center;
      gap: 2em;
      max-width:1200px;
    }
    .fruit-basket {
      width:100%;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: flex-start;
      .basket-title {
        background-color: $lightBrown;
        border-radius: $borderRadius;
        text-align: center;
        width: 100%;
        align-items: center;
      }
    }
  }
}
